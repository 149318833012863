import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard',  icon: 'ni-chart-pie-35 text-primary', class: '' },
    { path: '/inbox', title: 'Inbox',  icon: 'ni-email-83 text-primary', class: '' },
    { path: '/usulan', title: 'List Usulan/Dokumen',  icon: 'ni-tv-2 text-primary', class: '' }
    
];

export const ADM: RouteInfo[] = [
  { path: '/users', title: 'User Management',  icon: 'ni-single-02 text-primary', class: '' },
  { path: '/vendor', title: 'Vendor Management',  icon: 'ni-collection text-primary', class: '' }

  //{ path: '/fase', title: 'Expire',  icon: 'ni-bullet-list-67 text-primary', class: '' },
  //{ path: '/generatecoin', title: 'Generate IPCoin',  icon: 'ni-money-coins text-primary', class: '' },
  ///{ path: '/generatebiling', title: 'Generate Biling',  icon: 'ni-bullet-list-67 text-primary', class: '' },
  //{ path: '/rekapbiling', title: 'Rekap Biling',  icon: 'ni-bullet-list-67 text-primary', class: '' },

];
export const TOOL: RouteInfo[] = [
  //{ path: '/history', title: 'History Deleted',  icon: 'fas fa-trash-alt text-danger', class: '' },

];



@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public menuAdm: any[];
  public menuTool: any[];
  //public menuPeg: any[];
  public isCollapsed = true;
  public namaUser:any;
  public parre:any;
  constructor(private router: Router) { }

  ngOnInit() {
    const userData = JSON.parse(localStorage.getItem('userLontar'));   
    if(userData){
      this.namaUser = userData.username_;
      this.parre = userData.parent_;
    }
    

    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.menuAdm = ADM.filter(menuItem => menuItem);
    this.menuTool = TOOL.filter(menuItem => menuItem);
    //this.menuPeg = KEPEGAWAIAN.filter(menuItem => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });
  }
}
