import { Component, OnInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    const userData = JSON.parse(localStorage.getItem('userLontar'));   
    if (userData) {
      window.location.href = '#/dashboard';
    }else{
      window.location.href = '#/login';
    }
  }

}
