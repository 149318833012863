/*
  Name    : Configuration (IP Setting, Storage User data, Logout)
  Author  : @ilhamhimura
*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()
export class myConfig {
    
//public ApiURL:string        = 'http://localhost:8080/apilontar/api/';
  
  public ApiURL:string        = 'https://apidotatu.lontarbase.com/index.php/api/';
  public Imgurl:string        = 'https://apidotatu.lontarbase.com/imgbg';
  public GETURL:string        = 'https://apidotatu.lontarbase.com/';
  public CRONURL:string       = 'https://apidotatu.lontarbase.com/idcron/';
  public GETQR:string       = 'https://apidotatu.lontarbase.com/parser/qrcode/';
  
  /* local */
  /*
  public ApiURL:string        = 'http://localhost:8080/apilontar/api/';
  public Imgurl:string        = 'http://localhost:8080/apilontar/imgbg';
  public GETURL:string        = 'http://localhost:8080/apilontar/';
  public CRONURL:string       = 'http://localhost:8080/apilontar/idcron/';
  public GETQR:string       = 'http://localhost:8080/apilontar/parser/qrcode/';
  */
  public userData:any;
  public keyToken:any;



  getAPIURL() {
    return this.ApiURL;
  }




  getToken() {
    if(localStorage.getItem('userLontar') != ""){
      this.userData = JSON.parse(localStorage.getItem('userLontar'));
      this.keyToken    = this.userData.token;
    }
    return this.keyToken;
  }

  logoutService(){
    localStorage.setItem('userLontar', '');
    localStorage.clear();
    localStorage.removeItem('userLontar');
    window.location.href = '#/login';
  }
}
